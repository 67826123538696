import { RouteProp } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { Text, View } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions, Platform, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

import {
  DayClosedMessage,
  DayMenuDetails,
  DayMenuHeader,
  DayMenuProvider,
  MenuLoading,
  NoMenuAvailable,
} from '@/components';
import Colors from '@/constants/Colors';
import { useRootStore } from '@/data';
import { useDeepLinkAddLocation } from '@/hooks/useDeepLinkAddLocation';
import { getClosestDayPartIndex } from '@/utils';

const initialLayout = { width: Dimensions.get('window').width };

const DayPartLabel = ({
  route,
  focused,
}: {
  route: { title: string };
  focused: boolean;
}) => (
  <Text
    ellipsizeMode="tail"
    style={focused ? styles.focusedTabText : styles.tabText}
  >
    {route.title}
  </Text>
);

const SceneWrapper = ({ route }: { route: { key: string } }) => {
  return (
    <DayMenuProvider dayPart={route.key}>
      <DayMenuDetails dayPart={route.key} />
    </DayMenuProvider>
  );
};

// styling for TabView is not very intuitive
// we had to refer to its source code
// https://github.com/satya164/react-native-tab-view/blob/main/src/TabBarItem.tsx#L269-L275
// to make sense of it
const DayMenuScreen = observer(
  ({ route }: { route: RouteProp<{ params: { dayPart: string } }> }) => {
    const { menuStore } = useRootStore();
    const { dayParts, isDayClosed } = menuStore;

    useDeepLinkAddLocation();

    const routes = useMemo(
      () => dayParts?.map(x => ({ key: x, title: x })),
      [dayParts],
    );

    const renderScene = useMemo(() => {
      if (!routes || !routes.length) {
        return null;
      }
      return SceneMap(
        routes.reduce(
          (result, scene) => ({
            ...result,
            [scene.key]: SceneWrapper,
          }),
          {},
        ),
      );
    }, [routes]);

    const [index, setIndex] = useState(-1);

    useEffect(() => {
      if (!dayParts?.length) {
        return;
      }

      const defaultDayPart = route?.params?.dayPart;
      const defaultIndex = defaultDayPart
        ? dayParts!.findIndex(x => x === defaultDayPart) || 0
        : getClosestDayPartIndex(dayParts || []);
      setIndex(defaultIndex);
    }, [dayParts, route]);

    if (!routes) {
      return <MenuLoading />;
    }

    if (isDayClosed) {
      return <DayClosedMessage />;
    }

    if (!routes.length) {
      return <NoMenuAvailable />;
    }

    if (index === -1) {
      return <MenuLoading />;
    }

    const TabWrapper = Platform.OS === 'web' ? View : React.Fragment;
    const wrapperProps =
      Platform.OS === 'web' ? { style: styles.tabWrapper } : {};

    return (
      <TabView
        key={dayParts?.join('-')}
        initialLayout={initialLayout}
        // despite the documentation stating that index and onIndexChange are required
        // and that TabView is a controlled component
        // this has performance implications and it actually is not required at all
        // because it uses PagerView under the hood and these only serve as initial values
        navigationState={{ index, routes }}
        onIndexChange={Platform.OS === 'web' ? setIndex : () => {}}
        renderScene={renderScene!}
        renderTabBar={props => (
          <TabWrapper {...wrapperProps}>
            <TabBar
              {...props}
              indicatorStyle={styles.tabIndicatorStyle}
              scrollEnabled
              style={styles.tabViewStyle}
              tabStyle={styles.tabStyle}
              labelStyle={styles.tabText}
              renderLabel={DayPartLabel}
              contentContainerStyle={styles.tabBackground}
            />
          </TabWrapper>
        )}
      />
    );
  },
);

const styles = StyleSheet.create({
  tabIndicatorStyle: {
    height: 4,
    backgroundColor: Colors.primaryGreen,
    ...(Platform.OS === 'web' ? {} : { width: 0.5 }),
  },
  tabStyle: {
    minHeight: 30,
    paddingHorizontal: 20,
    paddingBottom: 20,
    width: 'auto',
  },
  focusedTabText: {
    paddingHorizontal: 2,
    paddingVertical: 8,
    fontFamily: 'Lato_400Regular',
    color: Colors.primaryDark,
    textTransform: 'capitalize',
  },
  safeArea: { flex: 0, backgroundColor: 'white' },
  tabText: {
    paddingHorizontal: 2,
    paddingVertical: 10,
    marginBottom: -20,
    fontFamily: 'Lato_400Regular',
    color: Colors.secondaryDark,
    textTransform: 'capitalize',
    width: 'auto',
  },
  tabViewStyle: {
    backgroundColor: Colors.background,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderColor,
    shadowRadius: 0,
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderWidth: 0,
    backgroundColor: Colors.background,
  },
  tabBackground: {
    backgroundColor: Colors.background,
  },
});

//.css-view-1dbjc4n
//.css-view-1dbjc4n

export default function DayMenuScreenWithProvider(props: {
  route: RouteProp<{ params: { dayPart: string } }>;
}) {
  return (
    <>
      <SafeAreaView style={styles.safeArea} edges={['top']} />
      <DayMenuHeader />
      <DayMenuScreen {...props} />
    </>
  );
}
