import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import {
  Badge,
  Divider,
  Heading,
  HStack,
  IBadgeProps,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import React, { useContext, useImperativeHandle, useRef } from 'react';
import { Animated, Easing } from 'react-native';

import { useRootStore } from '@/data';
import { IMenuItem } from '@/types';
import { filterRecipeTags, formatMenuItem } from '@/utils';

import { DayMenuContext } from '../DayMenuProvider';
import { Card } from './Card';
import { DietaryFlagBadge } from './DietaryFlagBadge';
import { NutritionalBlock } from './NutritionalBlock';

const FoodBadge = ({ text, ...props }: { text: string } & IBadgeProps) => (
  <Badge
    colorScheme="green"
    bg="mg.green.100"
    rounded="xl"
    my="0.5"
    py="0.5"
    maxW="full"
    {...props}
  >
    <Text
      textTransform="uppercase"
      fontSize={{ base: '2xs', lg: 'sm' }}
      maxW="full"
    >
      {text}
    </Text>
  </Badge>
);

export const FoodCard = observer(
  React.forwardRef(({ item }: { item: IMenuItem }, ref) => {
    const { menuStore } = useRootStore();

    const { calorieDisplay } = menuStore;
    const dietaryFlagIds = item.attributes.recipeDietaryFlagsIds || [];
    const isIncomplete = !item.attributes.recipeNutrition?.allergensComplete;
    const hasRecipeTags = !!item.attributes.recipeTags?.length;
    const visibleDietaryFlags = dietaryFlagIds.filter(
      id => menuStore.dietaryFlagIds?.indexOf(id) >= 0,
    );

    const { serving, calories, fats, protein, carbs } = formatMenuItem(item);
    const animation = useRef(new Animated.Value(0)).current;
    const interpolated = animation.interpolate({
      inputRange: [0, 0.5, 1, 1.5, 2, 2.5, 3],
      outputRange: [0, -8, 0, 8, 0, -6, 0],
    });
    const style = {
      transform: [{ translateX: interpolated }],
      maxWidth: '100%',
    };

    const animateBadge = () => {
      animation.setValue(0);
      Animated.timing(animation, {
        duration: 400,
        toValue: 3,
        easing: Easing.bounce,
        useNativeDriver: true,
      }).start();
    };

    useImperativeHandle(ref, () => ({
      animateBadge: isIncomplete ? animateBadge : null,
    }));

    return (
      <Card minW="full" minH="24">
        <VStack flex="1" marginBottom="2">
          <Heading
            color="mg.dark.300"
            fontSize={{ base: 'lg', lg: '2xl' }}
            fontWeight="600"
            marginBottom="2"
          >
            {item.attributes.displayName}
          </Heading>
          <HStack flexWrap="wrap" space={[1, 2]}>
            {hasRecipeTags
              ? filterRecipeTags(item.attributes.recipeTags!).map(tag => (
                  <Animated.View style={style} key={tag.id}>
                    <FoodBadge text={tag.note} />
                  </Animated.View>
                ))
              : visibleDietaryFlags.map(id => (
                  <DietaryFlagBadge key={id} dietaryFlagId={id} />
                ))}
            {isIncomplete && !hasRecipeTags ? (
              <Animated.View style={style}>
                <FoodBadge text="Ask a staff member for nutritional and allergen info" />
              </Animated.View>
            ) : (
              visibleDietaryFlags.length === 0 && (
                <FoodBadge text="No allergens" />
              )
            )}
          </HStack>
        </VStack>
        {calorieDisplay === 'in_header' && (
          <HStack space={[2, 2.5]}>
            <NutritionalBlock label="Serving" value={serving} />
            <Divider orientation="vertical" bg="mg.green.300" mx="1.5" />
            <NutritionalBlock label="Cal" value={calories} />
            <NutritionalBlock label="Carb" value={carbs} />
            <NutritionalBlock label="Fat" value={fats} />
            <NutritionalBlock label="Pro" value={protein} />
          </HStack>
        )}
      </Card>
    );
  }),
);

export const PressableFoodCard = ({ item }: { item: IMenuItem }) => {
  const navigation = useNavigation();
  const cardRef = useRef<{ animateBadge: Function }>();
  const dayPart = useContext(DayMenuContext);
  const { menuStore } = useRootStore();
  const { calorieDisplay } = menuStore;

  const handlePress = () => {
    if (calorieDisplay === 'hide_nutrition_block') {
      if (cardRef.current?.animateBadge) {
        cardRef.current.animateBadge();
      }
    } else {
      if (cardRef.current?.animateBadge) {
        cardRef.current.animateBadge();
      } else {
        //@ts-ignore
        navigation.navigate('NutritionalInfo', {
          itemId: item.id,
          dayPart,
        });
      }
    }
  };

  return (
    <Pressable onPress={handlePress}>
      <FoodCard item={item} ref={cardRef} />
    </Pressable>
  );
};
